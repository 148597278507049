import { makeAutoObservable } from "mobx";

export class PaginationStore {
  page: number;
  pageCount: number;
  totalCount: number;

  constructor() {
    makeAutoObservable(this);
    this.page = 0;
    this.pageCount = 1;
  }

  next() {
    if (this.page > 0) this.page--;
  }

  prev() {
    if (this.page > 0) this.page--;
  }

  setPage(page: number) {
    this.page = page;
  }

  setPageCount(pageCount: number) {
    this.pageCount = pageCount;
  }

  setPagination(page: number, pageCount: number, totalCount: number) {
    this.page = page;
    this.pageCount = pageCount;
    this.totalCount = totalCount;
  }
}